.partners-wrapper {
    max-width: 100%;
    margin: 130px 0 0 0;
}

.partners-wrapper .container {
    position: relative;
}

.button-partners{
    position: absolute;
    right: 65px;
    bottom: 0px;
}

.partners-header h2{
    margin: 0;
    padding: 0;
    font-size: 48px;
    font-weight: 600;
    text-align: initial;
    margin-bottom: 50px;
}

.partners-header p {
    margin: 0;
    text-align: initial;
    font-weight: 500;
    font-size: 20px;
    line-height: 123.02%;
    color: #1F1F1F;
}

.partners-table{
    display: flex;
    flex-direction: row;
    margin-top: 69px;
}

.partners-table div {
    flex: 1;
}

.item-number{
    position: relative;
    display: flex;
    height: 72px;
    margin-bottom: 88px;
}

.item-number img{
    position: absolute;
}

.item-number p {
    margin: 0;
    font-weight: normal;
    padding: 0 0 0 1.5rem;
    font-size: 24px;
    line-height: 123.02%;
    text-align: initial;
    position: relative;
    top: 36px;
}

.modal-partners-form{
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 50px;
}

.modal-partners-form div{
    display: flex;
    justify-content: flex-end;
}

.modal-partners-form h2{
    margin: 0;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 35px;
}

.modal-partners-form input{
    border: none;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    border-bottom: 2px solid #14AE5C;
    margin-bottom: 35px;
    padding: 10px;
}

.modal-partners-form input:focus{
    outline: none;
}

.modal-partners-form input::placeholder{
    padding-left: 5px;
}

.modal-partners-form input.inperror {
    border-bottom: 1px solid red;
}
button:disabled {
    cursor: auto;
    opacity: .4;
}

@media screen and (max-width: 1024px) {
    .partners-wrapper {
        margin-top: 65px;
    }
    .partners-header h2 {
        font-size: 34px;
        margin-bottom: 45px;
    }
    .modal-partners-form h2 {
        font-size: 34px;
    }
}

@media screen and (max-width: 860px) {
    .partners-table{
        display: block;
    }
}

@media screen and (max-width: 640px) {

    .partners-wrapper{
        margin-bottom: 5rem;
    }
    
    .button-partners{
        position: relative;
        right: 0;
        margin-top: 0;
    }
    .modal-partners-form div {
        justify-content: center;
    }
    .modal-partners-form h2 {
        font-size: 26px;
    }
    .modal-partners-form input {
        font-size: 18px;
    }
    .modal-partners-form {
        padding: 30px;
    }
}


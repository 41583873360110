.about-wrapper{
    padding: 120px 0 130px 0;
}

.about-wrapper .container {
    display: flex;
    flex-direction: column;
}

.about-info{
    display: flex;
    margin-top: 95px;
    justify-content: space-between;
    gap: 125px;
}

.about-header{
    font-size: 48px;
    font-weight: 600;
    text-align: justify;
}

.about-text{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    max-width: 35rem;
}

.about-text p {
    text-align: initial;
    margin: 0;
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
}

.about-text img{
    width: 30px;
    margin-right: 2rem;
}

.about-track-img img{
    width: 100%;
}

@media screen and (max-width: 1440px) {
    .about-track-img {
        margin-right: -65px;
    }
}

@media screen and (max-width: 1024px) {
    .about-wrapper {
        padding: 60px 0 65px;
    }
    .about-header{
        font-size: 34px;
    }
    .about-info {
        margin-top: 40px;
        gap: 20px;
    }
}

@media screen and (max-width: 860px) {
    .about-info {
        flex-direction: column;
    }
    .about-text {
        max-width: 100%;
    }
}

@media screen and (max-width: 640px) {
    .about-wrapper{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .about-header{
        font-size: 26px;
        line-height: 31px;
    }
    .about-text{
        flex-direction: column;
        gap: 20px;
    }
}



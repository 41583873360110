.comparison-wrapper{
    margin: 130px 0 0 0;
    max-width: 100%;
}
.comparison-header{
    font-size: 48px;
    font-weight: 600;
    text-align: initial;
    margin-bottom: 50px;
}

.comparison-wrapper img{
    max-width: 100%;
}

.download-pdf-com{
    display: flex;
    align-items: flex-start;
    gap: 30px;
    justify-content: space-between;
    margin-top: 30px;
}
.logo-paragraph{
    display: flex;
    gap: 25px;
}
.download-pdf-com .logo-paragraph img {
    max-width: 50px;
}

.download-pdf-com .logo-paragraph p {
    cursor: pointer;
    margin: 0;
    align-self: end;
    font-size: 20px;
    font-weight: 500;
    color: #1F1F1F;
}

.download-pdf-com .logo-paragraph p a {
    text-decoration: underline;
}
.download-pdf-com .logo-paragraph p a:hover {
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .comparison-wrapper {
        margin-top: 65px;
    }
    .comparison-header {
        font-size: 34px;
    }
}

@media screen and (max-width: 640px) {
    .download-pdf-com{
        flex-direction: column;
        align-items: center;
    }
    .table-img{
        display: none;
    }
    .logo-paragraph {
        align-self: flex-start;
    }
    .comparison-header {
        font-size: 26px;
        line-height: 31px;
    }
}

.checkout-wrapper{
    max-width: 100%;
    margin: 130px 0 0 0;
}
.checkout-wrapper .container {
    position: relative;
}
.checkout-wrapper a{
    text-decoration: none;
    cursor: auto;
}

.checkout-wrapper a:visited{
    color: inherit;
}

.checkout-header{
    margin-bottom: 2rem;
}

.checkout-header h2{
    margin: 0;
    padding: 0;
    font-size: 48px;
    font-weight: 600;
    text-align: initial;
    margin-bottom: 50px;
}

.checkout-header p {
    margin: 0 0 40px 0;
    text-align: initial;
    max-width: 1077px;
    font-weight: 500;
    font-size: 20px;
    line-height: 123.02%;
}
.checkout-header p a {
    text-decoration: underline;
    cursor: pointer;
}
.checkout-header p a:hover {
    text-decoration: none;
}

.checkout-form{
    display: flex;
}

.checkout-form div {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.checkout-form-field{
    display: flex;
    flex-direction: column;
}

.checkout-form-field input{
    border: none;
    border-bottom: 2px solid #14AE5C;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding: 10px;
}

.checkout-form-img-left {
    max-width: 628px;
    margin-right: 30px;
}

.checkout-form-field input:focus{
    outline: none;
    background-color: transparent;
}

.checkout-form-field input::placeholder, .checkout-form-field textarea::placeholder{
    color: #000;
    opacity: 0.6;
}

.checkout-form-field textarea {
    padding: 0;
    margin: 0;
    resize: none;
    border: none;
    height: 10rem;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding: 10px;
    border-bottom: 2px solid #14AE5C;
    font-family: 'Inter', sans-serif;
}

.checkout-form-field textarea:focus{
    outline: none;
    background-color: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    box-shadow:inset 0 0 0 1000px #fff;
}

.button-buy-checkout{
    position: absolute;
    right: 65px;
    margin-top: 25rem;
}
.checkout-form-field input.inperror, .checkout-form-field textarea.inperror {
    border-bottom: 2px solid red;
}
button:disabled {
    cursor: auto;
    opacity: .4;
}
.deal-img {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .checkout-wrapper {
        margin-top: 65px;
        margin-bottom: 265px;
    }
    .checkout-header h2 {
        font-size: 34px;
        margin-bottom: 45px;
    }
    .checkout-form .checkout-form-img-left{
        display: none;
   }
}

@media screen and (max-width: 640px) {
    .checkout-form{
        display: block;
    }
    .checkout-form div{
        flex: 0;
        justify-content: center;
    }
    .checkout-wrapper {
        margin-bottom: 0px;
    }
   .button-buy-checkout{
        position: relative;
        margin: 2rem 5rem;
        right: auto;
    }
}


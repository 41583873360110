@import url('https://fonts.googleapis.com/css?family=Inter');
body {
  margin: 0;
  font-family: 'Inter', sans-serif;;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
    margin-left: auto;
    margin-right: auto;
    min-width: 280px;
    max-width: 1310px;
    padding: 0 65px;
}
.container:after, .container:before {
    content: '';
    display: table;
    line-height: 0;
}
.container:after {
    clear: both;
}

@media screen and (max-width: 860px) {
    .popup-content {
        width: 80% !important;
    }
}

@media screen and (max-width: 640px) {
    .container {
      padding: 0 20px;
    }
    .popup-content {
        width: 94% !important;
    }
}

.product-wrapper {
 width: calc(33% - 32px);
 background-color: rgb(255, 255, 255);
 box-shadow: 0px 0px 45px 4px rgba(0, 0, 0, 0.07);
 border-radius: 10px;
 display: flex;
 flex-direction: column;
 align-items: center;
 margin-bottom: 40px;
 padding: 25px 32px 48px;
 box-sizing: border-box;
 justify-content: space-between;
 gap:  25px;
}

.product-wrapper img{
    max-height: 333px;
    align-self: center;
    margin-bottom: 20px;
    max-width: calc(100% - 50px);
}

.info-product-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: initial;
    gap: 20px;
    width: 100%;
}

.info-product-card h2, p{
    margin: 0;
}

.info-product-card p{
    font-size: 14px;
    line-height: 17px;
    color: #000000;
}

.info-product-card h2{
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 5px;
}

.info-product-card span img{
    max-width: 20px;
    margin-right: 10px;
    margin-bottom: 0px;
}

.price-product-card{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 25px;
    text-align: left;
    display: block;
}

.button-buy-product{
    display: flex;
    width: 100%;
    justify-content: center;
}
.button-buy-product .buy-button-wrapper button, .button-buy-product a {
    width: 100%;
}
.info-product-bot {
    width: 100%;
}
.buy-button-wrapper2 {
    width: 100%;
}
.buy-button-wrapper2 button {
    border: none;
    height: 56px;
    width: 100%;
    background-color: #14AE5C;
    cursor: pointer;
    color: white;
    font-size: 18px;
    border-radius: 6px;
    opacity: .8;
    transition: .2s;
}
.buy-button-wrapper2 button:hover {
    opacity: 1;
}
.modal-product {
    /*padding: 5px !important;*/
}
.modal-product-wr {
    padding: 45px;
}
.modal-product h2 {
    margin: 0 0 70px 0;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    color: #1F1F1F;
}
.modal-product-descr {
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    color: #000000;
    margin-bottom: 80px;
}
.modal-product-descr p {
    margin-bottom: 15px;
}
.modal-product-bot {
    display: flex;
    justify-content: space-between;
    gap: 60px;
}
.modal-product-bot h2 {
    font-size: 22px;
    line-height: 26px;
    margin-bottom: 40px;
}
.modal-product-bot img {
    max-height: 200px;
}
.product-modal-content {
    min-width: 860px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 50px;
    box-sizing: border-box;
}
.charact {
    width: 100%;
}
.charact ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  line-height: 24px;
}
.charact ul li {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.charact ul li div:nth-child(2) {
  flex: 1 0;
  border-bottom: 1px dotted rgba(0, 0, 0, 0.33);
  height: 1em;
  margin: 0 .4em;
}
.charact ul li div:nth-child(3) {
  width: 285px;
}
.modal-product {
    display: none;
}
.modal-product.active {
    display: block;
}

@media screen and (max-width: 1024px) {
    .product-wrapper {
        width: calc(50% - 32px);
    }
}
@media screen and (max-width: 860px) {
    .product-modal-content {
        width: calc(100% - 40px) !important;
        min-width: initial;
    }
    .modal-product-bot img {
        display: none;
    }
}
@media screen and (max-width: 640px) {
    .product-wrapper {
        width: 100%;
        padding: 20px 30px 30px 30px;
    }
    .info-product-card h2 {
        font-size: 23px;
        line-height: 28px;
    }
    
    .product-modal-content {
        /*padding: 30px !important;*/
    }
    .modal-product h2 {
        font-weight: 600;
        font-size: 23px;
        line-height: 28px;
        margin-bottom: 35px;
    }
    .modal-product-descr {
        margin-bottom: 20px;
    }
    .modal-product-bot img {
        max-height: initial;
        max-width: 300px;
        display: flex;
        align-self: center;
    }
    .modal-product-bot {
        flex-direction: column;
        gap: 20px;
    }
    .modal-product-buy {
        text-align: center;
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }
    .charact ul li {
        flex-direction: column;
    }
    .charact ul li div:nth-child(3) {
        width: 100%;
    }
    .charact ul li div:nth-child(1) {
        font-weight: 600;
    }
    .modal-product-wr {
        padding: 25px;
    }
}
.quality-wrapper{
    max-width: 100%;
    margin-top: 130px;
}

.quality-header{
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 85px;
    text-align: initial;
}

.quality-certificates{
    display: flex;
    justify-content: space-between;
}

.item{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.item img{
    max-width: 20rem;
    height: 100%;
    max-height: 412px;
    margin-bottom: 30px;
}

.item p{
    margin: 0;
    text-align: initial;
    font-weight: 500;
    font-size: 20px;
    line-height: 123.02%;
    color: #1F1F1F;
}

@media screen and (max-width: 1024px) {
    .quality-wrapper {
        margin-top: 65px;
    }
    .quality-header {
        font-size: 34px;
        margin-bottom: 45px;
    }
}

@media screen and (max-width: 860px) {
    .quality-certificates{
        flex-direction: column;
    }
    .item {
        margin-bottom: 50px;
    }
    .item p {
        text-align: center;
    }
}


.product-table-wrapper{
}

.product-table-wrapper .container {
    display: flex;
    flex-direction: column;   
}

.product-table-products{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    /*gap: 50px;*/
}

.product-table-header{
    margin: 0 0 85px 0;
    font-size: 48px;
    font-weight: 600;
    text-align: initial;
}

@media screen and (max-width: 1024px) {
    .product-table-header{
        font-size: 34px;
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 640px) {
    .product-table-header{
        font-size: 26px;
    }
}

@media screen and (max-width: 600px) {
    
}


.technology-wrapper{
    max-width: 100%;
    text-align: initial;
    margin-top: 130px;
}

.technology-wrapper .container {
    display: flex !important;
    flex-direction: column;
    justify-content: flex-start;
}

.technology-header{
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 45px;
}


.svg-paragraph{
    max-width: 30rem;
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.svg-paragraph img{
    max-width: 20px;
}

.svg-paragraph p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 123.02%;
    color: #1F1F1F;
}

.download-pdf{
    display: flex;
    align-items: center;
    gap: 25px;
    margin-bottom: 108px;
}
.download-pdf img {
    max-width: 50px;
}

.download-pdf p {
    cursor: pointer;
    margin: 0;
    align-self: end;
    font-weight: 500;
    font-size: 20px;
    color: #1F1F1F;
}

.download-pdf p a {
    text-decoration: underline;
}
.download-pdf p a:hover {
    text-decoration: none;
}

.good-mark{
    margin-left: 20rem;
}

.scheme{
    margin-top: 82px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.scheme img {
    max-width: 278px;
}

.scheme-adv{
    display: flex;
    justify-content: space-between;
    margin: 103px 0 0 0;
}

.little{
    max-width: 20rem;
}
.little a {
    text-decoration: underline;
}
.little a:hover {
    text-decoration: none;
}

@media screen and (max-width: 1024px) {
    .technology-wrapper {
        margin-top: 90px;
    }
    .technology-header {
        font-size: 34px;
    }
    .download-pdf {
        margin-bottom: 78px;
    }
}

@media screen and (max-width: 860px) {
    .good-mark {
        margin-left: auto;
    }
    .svg-paragraph {
        max-width: 100%;
    }
    .scheme {
        margin-top: 41px;
    }
    .scheme-adv {
        margin-top: 55px;
    }
}

@media screen and (max-width: 640px) {
    .technology-header {
        font-size: 26px;
        line-height: 31px;
        margin-bottom: 40px;
    }
    .download-pdf {
        margin-bottom: 40px;
    }
    .scheme{
        flex-direction: column;
    }
    .svg-paragraph img {
        max-width: 100%;
        width: 60px;
    }
    .scheme-adv {
        flex-direction: column;
        gap: 20px;
    }
    .time img {
        width: 40px;
    }
    .safe img {
        width: 54px;
    }
    .little {
        max-width: 100%;
    }
}

/*@media screen and (max-width: 600px) {
    .technology-header{
        font-size: 40px;
    }

    .download-pdf p{
        font-size: 18px;
    }

    .scheme{
        flex-direction: column;
    }

    .scheme-adv {
        margin: 3rem 0 0 0;
    }

    .good-mark{
        margin-left: 0;
    }
}*/

.header-wrapper{
    z-index: 2;
    position: absolute;
    top: 0px;
    background-color: inherit;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,.29); 
}

.header-wrapper .container {
    display: flex;
    flex-direction: row;
    text-align: left;
    min-height: 80px;
}

.img-logo {
    width: 50px;
    margin: 0 20px 0 0;
}

.top-link{
    text-transform: uppercase;
    text-align: initial;
    display: flex;
    align-items: flex-start;
    width: 90px;
    color: white;
    margin-right: 50px;
    justify-content: center;
    flex-direction: column;
    letter-spacing: 0.09em;
}
.top-link b {
    font-weight: 700;
    font-style: normal;
}

.burger-menu{
    width: 50px;
    margin-right: 50px;
    cursor: pointer;
}

.phonenumber {
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
}
.phonenumber a {
    color: #fff;
    text-decoration: none;
    transition: .2s;
}
.phonenumber a:hover {
    text-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
}
.phonenumber img {
    margin-right: 11px;
    margin-bottom: -3px;
}

@media screen and (max-width: 600px) {
    .header-wrapper .container:after, .header-wrapper .container:before {
        display: none;
    }
    .header-wrapper .container {
        justify-content: space-between;
    }
    .phonenumber{
        display: none;
    }
    .burger-menu{
        margin-right: 0px;
        justify-self: flex-end;
    }
    .top-link{
        margin-right: calc(100% - 210px);
    }
    

    
}

.buy-button-wrapper button, .buy-button-wrapper div{
    border: none;
    height: 56px;
    width: 300px;
    background-color: #14AE5C;
    cursor: pointer;
    color: white;
    font-size: 18px;
    border-radius: 6px;
    opacity: .8;
    transition: .2s;
}
.buy-button-wrapper button:hover,.buy-button-wrapper div:hover {
    opacity: 1;
}
.buy-button-wrapper button:disabled,.buy-button-wrapper div:disabled {
    opacity: .5;
}
.circle button, .circle div{
    border-radius: 50%;
    height: 150px;
    width: 150px;
}

.circle div{
    line-height: 150px;
    text-align: center;
}

.top-slider-wrapper{
    width: 100%;
    margin-bottom: 115px;
}

.top-slider-wrapper .container {
    height: 760px;
    position: absolute;
    left: 0px;
    right: 0px;
}

.buy-form{
    text-align: left;
    position: relative;
    color: white;
    font-size: 64px;
    top: 20%;
    left: 0;
    z-index: 1;
    font-weight: 600;
}

.buy-form .reagents{
    font-weight: normal;
    margin: 30px 0 30px 0;
    font-size: 17px;
    line-height: 21px;
}

.img-container{
    display: flex !important;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 760px;
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
.slide1 {
    background-image: url(../../assets/img/slider/1.jpg);
}
.slide2 {
    background-image: url(../../assets/img/slider/2.jpg);
}
.slide3 {
    background-image: url(../../assets/img/slider/3.jpg);
}
.slide4 {
    background-image: url(../../assets/img/slider/4.jpg);
}
.slide5 {
    background-image: url(../../assets/img/slider/5.jpg);
}

.dots{
    bottom: 50px;
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.top_dots {
    bottom: 50px;
}
.top_dots li  button:before {
    opacity: 1;
    color: #FFFFFF;
}

.dots li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;
}

.dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.dots li.slick-active {
    width: 40px;
    padding-bottom: 1px;
    padding-right: 5px;
}

.dots li.slick-active button:before {
    opacity: 1;
    color: #14AE5C;
    content: url('../../assets/img/pill.svg');
    margin-top: -3px;
}

.dots li button:before {
    /*font-family: 'slick';*/
    font-size: 18px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #FFFFFF;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1024px) {
    .top-slider-wrapper {
        margin-bottom: 65px;
    }
}

@media screen and (max-width: 640px) {
    .top-slider-wrapper {
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 600px) {
    .buy-form{
    font-size: 40px;
}

.buy-form .reagents{
    text-align: initial;
}
}


.advantage-wrapper{
    max-width: 100%;
    text-align: left;
}
.advantage-header {
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    color: #222020;
    margin-bottom: 90px;
}

.img-adv h2 {
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 30px 0;
}

.description {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 123.02%;
    color: #1F1F1F;
    margin-bottom: 5px;
    min-height: 125px;
}

.img-adv{
    padding: 0 30px;
    box-sizing: border-box;
}

.img-adv img{
    width: 100%;
}

.slick-slider {
    margin-left: -30px;
    width: calc(100% + 60px);
}

.graydots{
    bottom: -40px;
    position: absolute;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
}

.graydots li {
    position: relative;
    display: inline-block;
    width: 5px;
    height: 5px;
    margin: 0 4px;
    padding: 0;
    cursor: pointer;
}

.graydots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 5px;
    height: 5px;
    padding: 0px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.graydots li.slick-active {
    width: 45px;
    padding-bottom: 1px;
    padding-right: 5px;
}

.graydots li.slick-active button:before {
    opacity: 1;
    color: #14AE5C;
    content: url('../../assets/img/pill.svg');
    margin-top: -3px;
}

.graydots li button:before {
    font-size: 18px;
    line-height: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 5px;
    content: '•';
    text-align: center;
    opacity: 1;
    color: #C5C5C5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media screen and (max-width: 1024px) {
    .advantage-header {
        font-size: 34px;
        margin-bottom: 45px;
    }
    .img-adv h2 {
        font-size: 28px;
        line-height: 30px;
    }
}

@media screen and (max-width: 640px) {
    .advantage-header {
        font-size: 26px;
        line-height: 31px;
    }
    .img-adv h2 {
        font-size: 24px;
        line-height: 28px;
    }
}

@media screen and (max-width: 600px) {

    .advantage-wrapper .effect{
        flex-direction: column;
    }
}


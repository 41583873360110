.top-menu-wrapper-global {
    display: flex;
}
.burger-menu {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.top-menu-wrapper{
    background-color: white;
    border-radius: 6px;
    position: absolute;
    top: 81px;
    left: 10rem;
    display: none;
    padding: 2rem;
    transition-duration: .2s;
}

.top-menu-wrapper.active {
    display: flex;
}

.top-menu-wrapper .item-menu-wrap {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.subitem-menu{
    display: flex;
    gap: 20px;
    padding: 1rem;
    max-width: 25rem;
    cursor: pointer;
}

.subitem-menu div{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: initial;
}

.item-menu-wrap div img{
    max-width: 18px;
    align-self: flex-start;
    padding-top: 0.3rem;
    transition: .2s;
}

.item-menu-wrap div h3{
    font-weight: normal;
    margin: 0;
    opacity: .8;
    transition: .2s;
}
.item-menu-wrap div:hover h3 {
    opacity: 1;
}

.item-menu-wrap div p{
    margin: 0;
    font-size: 14px;
    color: green;
    opacity: 0.5;
}

@media screen and (max-width: 600px) {
    .top-menu-wrapper.active{
        padding: .5rem 0;
        right: 20px;
        max-width: 308px;
        display: block;
        box-sizing: border-box;
        left: auto;
    }
}


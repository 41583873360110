.footer-wrapper{
    max-width: 100%;
    margin-top: 110px;
    border-top: 2px solid #14AE5C;
}
.footer-wrapper .container {
    display: flex;
    flex-direction: column;
}
.bottom-menu{
    display: flex;
    flex-direction: column;
}

.footer-bot-wrapper {
    border-top:  2px solid rgba(20, 174, 92, 0.28);
}

.item-menu-container{
    display: flex;
    flex-direction: row;
    padding: 3rem 0 1rem 0;
    gap: 40px;
    align-items: center;
}

.item-menu-bottom{
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #222020;
    cursor: pointer;
}

.item-menu-bottom a{
    color: #222020;
    text-decoration: underline;
}
.item-menu-bottom a:hover {
    text-decoration: none;
}

.item-menu-container img{
    padding-right: 1rem;
}

.adress-container{
    padding: 3rem 0 3rem 7rem;
    display: flex;
    flex-direction: row;
    gap: 120px;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}

.adress-item{
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: initial;
}

.icon-menu{
    max-width: 20px;
}

.contacts-items{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.contacts-items div{
    display: flex;
    flex-direction: row;
}

.contacts-items div img {
    padding-right: 10px;
}

.contacts-items div p {
    margin: 0;
}

.contacts-items div p a:hover{
    text-decoration: underline;
}

.bottom-line{
    opacity: 0.75;
}

.copyright{
    padding: 26px 0 26px 7rem;
    text-align: initial;
    font-weight: 500;
}

@media screen and (max-width: 1024px) {
    .item-menu-container {
        flex-wrap: wrap;
    }
    .adress-container, .copyright {
        padding-left: 0px;
    }
}

@media screen and (max-width: 640px) {
    .item-menu-container{
        flex-direction: column;
        align-items: flex-start;
    }

    .adress-container {
        flex-direction: column;
        gap: 60px;
    }
}
